<template>
  <div class="col-start-1 col-end-3 my-4 overflow-y-auto overflow-x-hidden">
    <div
      v-if="!isNewDriver && !isNewDriverGroup"
      class="flex flex-col mb-4 w-full"
    >
      <span class="text-annotationColor text-sm font-semibold mt-4 mb-2">
        Фильтр
      </span>
      <div class="flex mb-3">
        <el-date-picker
          v-model="unitShift.dates"
          class="mr-4 w-full"
          type="datetimerange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="justify-self: end"
          :clearable="false"
          :placeholder="$t('routesTrips.trip.date.placeholder')"
          :format="dateTimeFormat"
          @change="handleTimeChange"
        />
      </div>
    </div>
    <div v-if="isNewDriver" class="flex flex-col mb-4 mt-4 w-full">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold"
      >
        {{ $t('objects_tabs.drivers.driver') }}
      </span>
      <el-select
        v-model="unitDriver.user.id"
        :class="['mt-3 w-7/12', { 'input-error': isDriverNameMissing }]"
        :disabled='disabledRole && role !== "EDITOR"'
        filterable="filterable"
      >
        <el-option
          v-for="(item, index) in users"
          :key="`${item.id}-driver+${index}`"
          :label="item.name"
          :value="item.id"
        />
        <transition name="el-zoom-in-top" />
        <div
          v-if="isDriverNameMissing"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ $t('objects.name') }}
        </div>
      </el-select>
      <div class="flex w-full mt-2 flex-col">
        <span
          class="
            mt-3
            text-annotationColor
            font-SourceSansPro
            text-sm
            font-semibold
          "
        >
          {{ $t('objects_tabs.drivers.from') }}
        </span>
        <el-date-picker
          v-model="unitDriver.date_from"
          class="my-3 w-2/3"
          popper-class="reportsDatepicker"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="false"
          :picker-options="pickerOptions"
          :format="dateTimeFormat"
        />
        <span
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          {{ $t('objects_tabs.drivers.to') }}
        </span>
        <el-date-picker
          v-model="unitDriver.date_to"
          class="mt-3"
          popper-class="reportsDatepicker"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="false"
          :picker-options="pickerOptions"
          :format="dateTimeFormat"
        />
      </div>
      <div class="flex mt-4 w-full">
        <div
          class="flex w-full"
          style="align-items: center; justify-content: space-between"
        >
          <span
            class="
              text-headerText text-sm
              font-SourceSansPro
              leading-10
              font-semibold
            "
          >
            {{ $t('objects_trailer_moreFields') }}
          </span>
          <svg
            class="
              cursor-pointer
              fill-annotationColor
              hover_fill-fillTrash
              transition-all
              ease-in-out
              duration-200
              transform
            "
            @click="isCreatingNewExtra = !isCreatingNewExtra"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
            />
          </svg>
        </div>
      </div>
      <div v-if="isCreatingNewExtra" class="flex flex-col mb-4">
        <div class="flex flex-col relative w-2/3">
          <span
            class="
              text-annotationColor
              font-SourceSansPro
              text-sm
              font-semibold
            "
          >
            {{ $t('name') }}
          </span>
          <el-input
            v-model="newExtraShift.name"
            type="text"
            :class="['mt-3', { 'input-error': isExtraNameMissing }]"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isExtraNameMissing"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('objects.name') }}
            </div>
          </transition>
        </div>
        <div class="flex flex-col relative">
          <span
            class="
              mt-6
              text-annotationColor
              font-SourceSansPro
              text-sm
              font-semibold
            "
          >
            {{ $t('value') }}
          </span>
          <div class="flex justify-between" style="align-items: center">
            <el-input
              v-model="newExtraShift.value"
              type="text"
              :class="['w-2/3', { 'input-error': isExtraValueMissingShift }]"
            />
            <transition name="el-zoom-in-top">
              <div
                v-if="isExtraValueMissingShift"
                class="text-notify text-xs top-full left-0 absolute"
              >
                {{ $t('objects_tabs.sensors.enterValue') }}
              </div>
            </transition>
            <skif-button 
              width="28"
              @click="createExtraDriver"
            >
              {{ $t('btn.save') }} 
            </skif-button>
          </div>
        </div>
      </div>
      <template v-else-if="isNewDriver">
        <div
          class="flex"
          v-for="(item, index) in unitDriver.custom_fields"
          :key="item.id"
        >
          <div class="flex justify-between" style="align-items: center">
            <el-input
              v-model="item.name"
              class="mb-4"
              style="width: 45%"
              type="text"
            />
            <el-input
              v-model="item.value"
              class="mb-4"
              style="width: 45%"
              type="text"
            />
            <svg
              class="
                mb-4
                cursor-pointer
                fill-annotationColor
                hover_fill-fillTrash
                transition-colors
                ease-in-out
                duration-200
              "
              width="12"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="(event) => { event.stopPropagation(); deleteExtraShift(item, index); }"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z"
              />
            </svg>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isNewDriverGroup" class="flex flex-col mb-4 mt-4 w-full">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold"
      >
        {{ $t('objects_tabs.drivers.driver') }}
      </span>
      <el-select
        v-model="unitdDriverGroupUser"
        filterable="filterable"
        :disabled='disabledRole && role !== "EDITOR"'
        :class="['mt-3 w-2/3', { 'input-error': isDriverNameMissing }]"
      >
        <el-option
          v-for="item in users"
          :key="`${item.id}-groupDriver`"
          :label="item.name"
          :value="item.id"
        />
        <transition name="el-zoom-in-top" />
        <div
          v-if="isDriverNameMissing"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ $t('objects.name') }}
        </div>
      </el-select>
      <div class="flex w-full mt-4 flex-col">
        <span
          class="
            text-annotationColor
            font-SourceSansPro
            text-sm
            font-semibold
            mb-2
            mt-2
          "
        >
          Период
        </span>
        <div class="flex flex-col w-3/3">
          <el-date-picker
            v-model="FgroupDates"
            type="datetimerange"
            range-separator="-"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
            :placeholder="$t('routesTrips.trip.date.placeholder')"
            
          />
          <span class="text-annotationColor text-sm font-semibold mx-1">
            по
          </span>
          <el-date-picker
            v-model="SgroupDates"
            type="datetimerange"
            range-separator="-"
            value-format="yyyy-MM-dd HH:mm:ss"
            :placeholder="$t('routesTrips.trip.date.placeholder')"
            :clearable="false"
          />
        </div>
      </div>
      <div class="flex mt-4 w-full">
        <div
          class="flex w-full"
          style="align-items: center; justify-content: space-between"
        >
          <span
            class="
              text-headerText text-sm
              font-SourceSansPro
              leading-10
              font-semibold
            "
          >
            {{ $t('objects_trailer_moreFields') }}
          </span>
          <svg
            class="
              cursor-pointer
              fill-annotationColor
              hover_fill-fillTrash
              transition-all
              ease-in-out
              duration-200
              transform
            "
            @click="isCreatingNewExtraGroup = !isCreatingNewExtraGroup"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
            />
          </svg>
        </div>
      </div>
      <div v-if="isCreatingNewExtraGroup" class="flex flex-col mb-4">
        <div class="flex flex-col relative w-2/3">
          <span
            class="
              text-annotationColor
              font-SourceSansPro
              text-sm
              font-semibold
            "
          >
            {{ $t('name') }}
          </span>
          <el-input
            v-model="newExtraShiftG.name"
            type="text"
            :class="['mt-3', { 'input-error': isExtraNameMissing }]"
          />
          <transition name="el-zoom-in-top">
            <div
              v-if="isExtraNameMissing"
              class="text-notify text-xs top-full left-0 absolute"
            >
              {{ $t('objects.name') }}
            </div>
          </transition>
        </div>
        <div class="flex flex-col relative">
          <span
            class="
              mt-6
              text-annotationColor
              font-SourceSansPro
              text-sm
              font-semibold
            "
          >
            {{ $t('value') }}
          </span>
          <div class="flex justify-between" style="align-items: center">
            <el-input
              v-model="newExtraShiftG.value"
              type="text"
              :class="['w-2/3', { 'input-error': isExtraValueMissingShift }]"
            />
            <transition name="el-zoom-in-top">
              <div
                v-if="isExtraValueMissingShift"
                class="text-notify text-xs top-full left-0 absolute"
              >
                {{ $t('objects_tabs.sensors.enterValue') }}
              </div>
            </transition>
            <skif-button width="28" @click="createExtraDriverG">
              {{ $t('btn.save') }} 
            </skif-button>
          </div>
        </div>
      </div>
      <template v-else-if="isNewDriverGroup">
        <div
          v-for="(item, index) in unitdDriverGroup.custom_fields"
          class="flex"
          :key="item.id"
        >
          <div class="flex justify-between" style="align-items: center">
            <el-input
              v-model="item.name"
              class="mb-4"
              style="width: 45%"
              type="text"
            />
            <el-input
              v-model="item.value"
              class="mb-4"
              style="width: 45%"
              type="text"
            />
            <svg
              class="
                mb-4
                cursor-pointer
                fill-annotationColor
                hover_fill-fillTrash
                transition-colors
                ease-in-out
                duration-200
              "
              width="12"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="(event) => { event.stopPropagation(); deleteExtraShift(item, index, false); }"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z"
              />
            </svg>
          </div>
        </div>
      </template>
    </div>
    <table
      v-if="!isNewDriver && !isNewDriverGroup"
      class="w-full table-fixed"
    >
      <thead>
        <tr class="border-b border-reallylightblue">
          <th class="bg-white top-0 sticky z-10 w-6">
            <skif-checkbox
              v-if="allunitsDrivers.length > 0"
              v-model="isAllChecked"
              class="p-2"
              style="margin-left: -8px"
              :indeterminate="isIndeterminate"
              @change="checkAllUsers($event)"
            />
          </th>
          <th class="bg-white top-0 sticky z-10 text-left">
            <span
              class="
                text-headerText text-sm
                font-SourceSansPro
                leading-10
                font-semibold
              "
            >
              {{ $t('objects_tabs.drivers.driver') }}
            </span>
          </th>
          <th class="bg-white top-0 sticky truncate z-10 text-left">
            <span
              class="
                text-headerText text-sm
                font-SourceSansPro
                leading-10
                font-semibold
              "
            >
              {{ $t('objects_tabs.drivers.from') }}
            </span>
          </th>
          <th class="bg-white top-0 sticky z-10 text-left">
            <div class="flex justify-between items-center">
              <span
                class="
                  text-headerText text-sm
                  font-SourceSansPro
                  leading-10
                  font-semibold
                "
              >
                {{ $t('objects_tabs.drivers.to') }}
              </span>
              <button
                v-if='stateIcon === "select" && shiftsSelected.length'
                class="h-6 w-9 group relative link focus_outline-none"
                type="button"
                @click="handlDeleteShifts"
              >
                <svg
                  class="
                    group-hover_fill-fillTrash
                    transition-colors
                    ease-in-out
                    duration-200
                  "
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#91B4E7"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                  />
                </svg>
                <skif-badge :value="shiftsSelected.length" />
              </button>
            </div>
          </th>
          <th class="w-6 text-center top-0 sticky z-10 bg-white">
            <el-dropdown
              :hide-on-click="true"
              :placement="'bottom-start'"
              @command="handleCommand"
            >
              <svg
                class="
                  cursor-pointer
                  fill-annotationColor
                  hover_fill-fillTrash
                  transition-all
                  ease-in-out
                  duration-200
                  transform
                "
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z"
                />
              </svg>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">Создание смены</el-dropdown-item>
                <!-- <el-dropdown-item command="b">
                  Создание группы смен
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(shift, i) in allunitsDrivers"
          class="cursor-pointer hover_bg-hoverrow"
          :key="i"
        >
          <td class="pr-2 text-darkblue">
            <skif-checkbox
              v-model="shift.isInArray"
              @change="changeChecked(shift, $event)"
            />
          </td>
          <td class="truncate pr-2 text-darkblue" @click="editShift(shift)">
            <span class="font-bold leading-10 text-xs">{{ shift.name }}</span>
          </td>
          <td class="truncate text-darkblue" @click="editShift(shift)">
            <span class="font-bold leading-10 text-xs">
              {{ shift.date_from }}
            </span>
          </td>
          <td class="truncate text-darkblue" @click="editShift(shift)">
            <span class="font-bold leading-10 text-xs">
              {{ shift.date_to }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import unitDriver from '../mixins/unitdriver'
import { unitDriversApi } from '@/api'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import MoreFieldIcon from '@/components/units/assets/MoreFieldIcon.vue'

export default {
  components: {
    MoreFieldIcon,
  },
  // mixins: [unitDriver],
  props: [
    'unit',
    'pickerOptions',
    'dateTimeFormat',
    'role',
    'isExtraNameMissing',
    'isCreateOrUpdateDriver',
    'isCreateOrUpdateDriverGroup',
  ],

  data() {
    return {
      filterStringDrivers: '',
      unitDrivers: [],
      isNewDriverGroup: false,
      isNewDriver: false,
      isEditDriver: false,
      isDriverNameMissing: false,
      isExtraValueMissingShift: false,
      unitDriver: {
        user: { id: '' },
        unit: { id: '' },
        date_from: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        date_to: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        custom_fields: [],
      },
      unitdDriverGroup: {
        unit: { id: '' },
        custom_fields: [],
        dates: [],
      },

      unitShifts: [],
      isNewShift: false,
      isShiftNameMissing: false,
      unitShift: {
        user: { id: '' },
        unit: { id: '' },
        dates: [],
      },
      FgroupDates: [],
      SgroupDates: [],
      isAllChecked: false,
      stateIcon: '',
      isIndeterminate: false,
      shiftsSelected: [],
      isShiftCreating: false,
      MonthsDays: [
        { name: 'Точные даты', key: 'exact_days' },
        { name: 'Все дни', key: 'alldays' },
        { name: 'Через день', key: 'after_day' },
        { name: 'Чётные дни', key: 'even_days' },
        { name: 'Нечётные дни', key: 'odd_days' },
        { name: 'Рабочие дни', key: 'work_days' },
        { name: 'Выходные дни', key: 'Weekends' },
        { name: 'Начало и конец смен', key: 'start_end_shifts' },
      ],
      daysFilter: '',
      isCreatingNewExtra: false,
      isCreatingNewExtraGroup: false,
      extraTabs: [],
      newExtraShift: {
        name: '',
        value: '',
      },
      newExtraShiftG: {
        name: '',
        value: '',
      },
      allunitsDrivers: [],
      unitdDriverGroupUser: '',
      defaultDate: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
    }
  },
  created() {
    this.FgroupDates = [this.defaultDate, this.defaultDate]
    this.SgroupDates = [this.defaultDate, this.defaultDate]
    const date = new Date()
    const yesterday = date.setDate(date.getDate() - 1)
    this.unitShift = {
      dates: [
        moment(yesterday).format('yyyy-MM-DD HH:mm:ss'),
        this.defaultDate,
      ],
    }
    this.getUnitDriversFilter()
  },
  methods: {
    resetCreateOrUpdateShiftForm() {
      this.unitDriver = {
        user: { id: '' },
        unit: { id: '' },
        date_from: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        date_to: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        custom_fields: [],
      }

      this.isEditDriver = false
      this.isNewDriver = false
    },
    editShift(shift) {
      this.unitDriver.id = shift.id
      this.unitDriver.unit.id = shift.unit.id
      this.unitDriver.user.id = shift.user.id
      this.unitDriver.date_from = shift.date_from
      this.unitDriver.date_to = shift.date_to
      this.unitDriver.custom_fields = shift.custom_fields
      this.unitDriver.company_id = shift.company_id

      this.$emit('is-edit-driver')
      this.isEditDriver = true
      this.isNewDriver = true
    },
    changeChecked(unit, $event) {
      this.shiftsSelected = $event.target.checked
        ? [...this.shiftsSelected, unit]
        : this.shiftsSelected.filter((el) => el.id !== unit.id)
      const selectedLength = this.shiftsSelected.length

      if (selectedLength === this.allunitsDrivers.length) {
        this.isAllChecked = true
        this.isIndeterminate = true
        this.stateIcon = 'select'
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
        this.stateIcon = 'select'
      }
    },
    checkAllUsers($event) {
      if ($event.target.checked) {
        this.shiftsSelected = this.allunitsDrivers
        this.shiftsSelected.forEach((el) => (el.isInArray = true))
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else {
        this.shiftsSelected.forEach((el) => (el.isInArray = false))
        this.shiftsSelected = []
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      }
    },
    deleteExtraShift(extratab, i, isGroup) {
      if (isGroup === false) {
        this.unitdDriverGroup.custom_fields.splice(i, 1)
      } else {
        this.unitDriver.custom_fields.splice(i, 1)
      }
    },
    createExtraDriverG() {
      if (this.newExtraShiftG.name.length && this.newExtraShiftG.value.length) {
        this.isExtraValueMissingShift = false
        this.unitdDriverGroup.custom_fields.push(this.newExtraShiftG)
        this.isCreatingNewExtraGroup = false
      } else {
        if (!this.newExtraShiftG.name.length) {
          this.isExtraNameMissing = true
        } else {
          this.isExtraNameMissing = false
        }
        if (!this.newExtraShiftG.value.length) {
          this.isExtraValueMissingShift = true
        } else {
          this.isExtraValueMissingShift = true
        }
      }
      this.newExtraShiftG = { name: '', value: '' }
    },
    createExtraDriver() {
      console.log('ok', this.newExtraShift)
      if (this.newExtraShift.name.length && this.newExtraShift.value.length) {
        this.isExtraValueMissingShift = false
        this.unitDriver.custom_fields.push(this.newExtraShift)
        this.isCreatingNewExtra = false
        this.newExtraShift = { name: '', value: '' }
        this.unitDriver.unit.id = this.unit.id
      } else {
        if (!this.newExtraShift.name.length) {
          this.isExtraNameMissing = true
        } else {
          this.isExtraNameMissing = false
        }
        if (!this.newExtraShift.value.length) {
          this.isExtraValueMissingShift = true
        } else {
          this.isExtraValueMissingShift = true
        }
      }
    },
    showCalendar() {
      console.log('show calendar')
    },

    isDriverValid() {
      let error = true
      if (!this.unitDriver.user.id) {
        this.isDriverNameMissing = this.unitDriver.user.id === ''
        error = false
      }
      return error
    },
    isDriverValidGroup() {
      let error = true
      if (!this.unitdDriverGroup.user.id) {
        this.isDriverNameMissing = this.unitdDriverGroup.user.id === ''
        error = false
      }
      return error
    },
    createOrUpdateDriverGroup() {
      if (this.unitdDriverGroup.company_id) {
        unitDriversApi.updateUnitDriver(
          this.unitdDriverGroup,
          () => {
            this.isNewDriverGroup = false
            this.getUnitDrivers()
          },
          (error) => {
            console.log('make it stop')
            this.$showError(error.response.data.message)
          }
        )
      } else {
        let dates = [
          {
            date_from: this.FgroupDates[0],
            date_to: this.FgroupDates[1],
          },
          {
            date_from: this.SgroupDates[0],
            date_to: this.SgroupDates[1],
          },
        ]
        this.unitdDriverGroup.dates = dates
        this.unitdDriverGroup.unit.id = this.unit.id
        if (this.unitdDriverGroupUser.length > 0) {
          this.unitdDriverGroup.user = { id: this.unitdDriverGroupUser }
        }
        unitDriversApi.createUnitDriverGroup(
          { ...this.unitdDriverGroup, unit: { id: this.unit.id } },
          () => {
            this.isNewDriverGroup = false
            this.getUnitDrivers()
            this.unitdDriverGroup.custom_fields = []
            this.newExtraShiftG.name = ''
            this.newExtraShiftG.value = ''
            this.FgroupDates = [this.defaultDate, this.defaultDate]
            this.SgroupDates = [this.defaultDate, this.defaultDate]
          },
          (error) => {
            this.$showError(error.response.data.message)
          }
        )
      }
    },
    createOrUpdateDriver() {
      let data = {
        from: 0,
        count: 100,
        unit: this.unit.id,
        date_from: this.unitShift.dates[0],
        date_to: this.unitShift.dates[1],
      }
      console.log('check save', this.unitDriver)

      const handleSuccess = () => {
        // reset all fields after save
        this.resetCreateOrUpdateShiftForm()

        this.getUnitDriversFilter(data)
      }

      if (this.unitDriver.company_id) {
        unitDriversApi.updateUnitDriver(
          this.unitDriver,
          handleSuccess,
          (error) => {
            console.log('make it stop')
            this.$showError(error.response.data.message)
          }
        )
      } else {
        unitDriversApi.createUnitDriver(
          { ...this.unitDriver, unit: { id: this.unit.id } },
          handleSuccess,
          (error) => {
            this.$showError(error.response.data.message)
          }
        )
      }
    },

    handlDeleteShifts() {
      const shiftsToDelete = this.shiftsSelected.map((shift) => shift.id)
      console.log('shifts to delete', shiftsToDelete)

      this.deleteDriver(shiftsToDelete)
      this.shiftsSelected = []
      this.stateIcon = 'normal'
      this.isAllChecked = false
      this.isIndeterminate = false
    },
    deleteDriver(unitdriverIds) {
      unitDriversApi.deleteUnitDrivers(
        unitdriverIds,
        () => {
          unitdriverIds.forEach((unitdriverId) => {
            if (this.unitDriver.id === unitdriverId) {
              this.isNewDriver = false
              this.unitDriver = {
                user: { id: '' },
                unit: { id: this.unit.id },
                date_from: this.from,
                date_to: this.to,
              }
            }
          })

          let newUsers = [];

          unitDriversApi.getUnitDriversFilter(
            {
              from: 0,
              count: 100,
              unit: this.unit.id,
              date_from: this.unitShift.dates[0],
              date_to:  this.unitShift.dates[1],
            },
            (response) => {
              const filteredList = response.data.list.filter((shift) => !unitdriverIds.includes(shift.id))

              filteredList.forEach(el => {
                this.users.forEach((item) => {
                  if (item.id === el.user_id) {
                    newUsers.push({ ...el, name: item.name })
                  }
                })
              })
              this.allunitsDrivers = newUsers

              this.unitDrivers = filteredList.map((driver) => {
                const user = this.userById(driver.user.id)
                return {
                  ...driver,
                  user: user,
                }
              })
            },
          )
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    async getUnitDriversFilter() {
      let newUsers = [];
      let data = {
        from: 0,
        count: 100,
        unit: this.unit.id,
        date_from: this.unitShift.dates[0],
        date_to:  this.unitShift.dates[1],
      }
      const response = await unitDriversApi.getUnitDriversFilter(data)
      response.data.list.forEach(el => {
        this.users.forEach((item) => {
          if (item.id === el.user_id) {
            newUsers.push({ ...el, name: item.name })
          }
        })
      })
      this.allunitsDrivers = newUsers

      this.unitDrivers = response.data.list.map((driver) => {
        const user = this.userById(driver.user.id)
        return {
          ...driver,
          user: user,
        }
      })
    },

    handleTimeChange(val) {
      let newUsers = []

      let data = {
        from: 0,
        count: 100,
        unit: this.unit.id,
        date_from: val[0],
        date_to: val[1],
      }
      unitDriversApi.getUnitDriversFilter(
        data,
        (response) => {
          response.data.list.forEach((el) =>
            this.users.forEach((item) => {
              if (item.id === el.user_id) {
                newUsers.push({ ...el, name: item.name })
              }
            })
          )

          this.allunitsDrivers = newUsers
          this.unitDrivers = response.data.list.map((driver) => {
            const user = this.userById(driver.user.id)
            return {
              ...driver,
              user: user,
            }
          })
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },

    getUnitDrivers() {
      let unitdrivers = []
      unitDriversApi.getUnitDrivers(
        this.unit.id,
        (response) => {
          response.data.list.forEach((el) => {
            this.users.forEach((item) => {
              if (item.id === el.user_id) {
                unitdrivers.push({ ...el, name: item.name })
              }
            })
            if (el['user_id'] === undefined) {
              unitdrivers.push(el)
            }
          })
          this.allunitsDrivers = unitdrivers
          this.unitDrivers = response.data.list.map((driver) => {
            const user = this.userById(driver.user.id)
            return {
              ...driver,
              user: user,
            }
          })
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    },
    handleCommand(command) {
      console.log('click', command)
      this.isAllChecked = false
      this.shiftsSelected = []
      this.stateIcon = 'normal'
      if (command === 'a') {
        this.isNewDriver = true
        this.$emit('isnew-driver', true)
      } else {
        this.isNewDriverGroup = true
        this.$emit('isnew-driver-group', true)
      }
    },
  },
  watch: {
    shiftsSelected(val) {
      this.$emit('shiftsselected', val.length)
    },
    isCreateOrUpdateDriver() {
      this.createOrUpdateDriver()
    },
    isCreateOrUpdateDriverGroup() {
      this.createOrUpdateDriverGroup()
    },
    'unitDriver.user.id': function (val) {
      if (val !== '') {
        this.isDriverNameMissing = false
      }
    },
  },
  computed: {
    ...mapGetters('login', ['disabledRole']),
    ...mapGetters('users', ['userById']),
    ...mapState('users', {
      users: (state) => {
        return state.users.filter((user) => user.is_driver)
      },
    }),
    filterDataDrivers() {
      return this.unitDrivers.filter(
        (user) =>
          JSON.stringify(user)
            .toLowerCase()
            .indexOf(this.filterStringDrivers.toLowerCase()) !== -1
      )
    },
  },
}
</script>
